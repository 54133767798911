<template>
  <div class="main-container">
    <div class="create-account-container">
      <div class="create-client-person-header">
        <div class="header-text">Create New Client</div>
        <div style="margin-left: auto">
          <a style="color: #000000" href="/list-companies">
            <CloseOutlined class="cross-icon" />
          </a>
        </div>
      </div>
      <a-form :ref="formRef">
        <div class="create-account-basic-details">
          <div class="basic-account-info-form">
            <div class="form-sub-section">
              <div class="add-account-form-elements left-form-elements">
                <a-form-item v-bind="validateInfos.name">
                  <span class="input-label">Company</span>
                  <span class="required-input">*</span>
                  <a-input
                    class="form-input"
                    v-model:value="modelRef.name"
                  ></a-input>
                </a-form-item>
              </div>
              <div class="add-account-form-elements">
                <a-form-item v-bind="validateInfos.companyId">
                  <span class="input-label">Company ID</span
                  ><span class="required-input">*</span>
                  <a-input
                    class="form-input"
                    v-model:value="modelRef.companyId"
                  ></a-input>
                  <a-checkbox v-model:checked="companyStatus"
                    >Set as active</a-checkbox
                  >
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-account-form-elements left-form-elements">
                <a-form-item v-bind="validateInfos.type">
                  <span class="input-label">Company Type</span
                  ><span class="required-input">*</span>
                  <a-select
                    class="select-input"
                    v-model:value="modelRef.type"
                    :options="companyTypeOptions"
                  ></a-select>
                </a-form-item>
              </div>
              <div class="add-account-form-elements">
                <a-form-item>
                  <span class="input-label">Country</span>
                  <a-input
                    class="form-input"
                    v-model:value="officeRef.officeAddress.country"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-account-form-elements left-form-elements">
                <a-form-item>
                  <span class="input-label">Date</span>
                  <a-date-picker
                    :format="dateFormat"
                    class="date-input"
                    v-model:value="modelRef.date"
                    style="border-radius: 15px !important; margin-top: 0.75rem"
                  ></a-date-picker>
                </a-form-item>
              </div>
              <div class="add-account-form-elements">
                <a-form-item>
                  <span class="input-label">Website</span>
                  <a-input
                    class="form-input"
                    v-model:value="modelRef.website"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
            <a-divider class="section-divider" />
            <div class="form-sub-section">
              <div class="add-account-form-elements">
                <a-form-item v-bind="validateInfos.accountManagers">
                  <span class="input-label">Account Manager</span>
                  <span class="required-input">*</span>
                  <a-select
                    class="select-input"
                    mode="tags"
                    showArrow
                    v-model:value="modelRef.accountManagers"
                    :filter-option="false"
                    :options="accountManagerOptions"
                    @search="fetchUserData"
                    style="margin-top: 0.75rem"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-account-form-elements">
                <a-form-item v-bind="validateInfos.sectors">
                  <span class="input-label">Sector</span>
                  <span class="required-input">*</span>
                  <a-select
                    class="select-input"
                    mode="tags"
                    showArrow
                    v-model:value="modelRef.sectors"
                    :filter-option="false"
                    :options="sectorOptions"
                    @search="fetchSectorData"
                    style="margin-top: 0.75rem"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-account-form-elements">
                <a-form-item v-bind="validateInfos.industries">
                  <span class="input-label">Industry</span>
                  <span class="required-input">*</span>
                  <a-select
                    class="select-input"
                    mode="tags"
                    showArrow
                    v-model:value="modelRef.industries"
                    :filter-option="false"
                    :options="industryOptions"
                    @search="fetchIndustryData"
                    style="margin-top: 0.75rem"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-account-form-elements">
                <a-form-item v-bind="validateInfos.profile">
                  <span class="input-label">Profile</span>
                  <span class="required-input">*</span>
                  <a-textarea
                    class="form-text-area"
                    v-model:value="modelRef.profile"
                    :autoSize="{ minRows: 4, maxRows: 6 }"
                  />
                </a-form-item>
              </div>
            </div>
          </div>
        </div>
        <div
          class="create-account-additional-details"
          style="padding-right: 1rem"
        >
          <div class="additional-details-form">
            <div class="title">
              Additional Details <span class="optional-text">(Optional)</span>
            </div>
            <div class="form-sub-section">
              <div class="add-account-horizontal-elements">
                <a-form-item style="flex-basis: 100%; margin-bottom: 0.75rem">
                  <span class="input-label">Entity</span>
                  <PlusOutlined
                    class="icon-style"
                    placeholder="Entity Name"
                    style="margin-left: 1rem"
                    @click="addEntities"
                  />
                  <a-input
                    class="form-input"
                    v-model:value="entityRef.entityValue"
                  ></a-input>
                </a-form-item>
                <MinusOutlined
                  class="icon-style"
                  style="margin-right: 1rem; margin-top: 2.75rem"
                  @click="resetEntities"
                />
              </div>
              <div class="add-account-form-elements"></div>
            </div>
            <div class="additional-details-list">
              <p class="title">Entity Name:</p>
              <p v-for="(entity, index) in entityRef.entityList" :key="index">
                <span class="title" style="color: rgb(0, 0, 0)">{{
                  entity
                }}</span>
              </p>
            </div>
            <a-divider class="section-divider" style="width: 100%" />
            <div class="form-sub-section" style="padding-right: 1rem">
              <div class="add-account-horizontal-elements">
                <a-form-item
                  style="
                    margin-bottom: 0.75rem;
                    flex-basis: 50%;
                    margin-right: 2rem;
                  "
                >
                  <span class="input-label">Office</span>
                  <PlusOutlined
                    class="icon-style"
                    style="margin-left: 1rem"
                    @click="addOfficeInfo"
                  />
                  <a-input
                    placeholder="Office Name"
                    class="form-input"
                    v-model:value="officeRef.officeName"
                  ></a-input>
                </a-form-item>
                <a-form-item
                  style="
                    flex-basis: 50%;
                    margin-bottom: 0;
                    margin-left: 2rem;
                    padding-top: 1.35rem;
                  "
                >
                  <a-select
                    class="select-input"
                    placeholder="Office Type"
                    :options="officeTypeOptions"
                    label-in-value
                    v-model:value="officeRef.officeTypeValue"
                  >
                  </a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-account-horizontal-elements">
                <a-form-item style="flex-basis: 100%">
                  <a-input
                    placeholder="Office Address"
                    class="form-input"
                    v-model:value="officeRef.officeAddress.street"
                  ></a-input>
                </a-form-item>
              </div>
              <MinusOutlined
                class="icon-style"
                style="margin-top: 1.5rem"
                @click="resetOfficeFields"
              />
            </div>
            <div
              v-for="(office, index) in officeRef.officeTableList"
              :key="index"
              style="margin-bottom: 0.75rem"
            >
              <div class="additional-details-list">
                <div
                  style="display: flex; flex-direction: row; flex-basis: 50%"
                >
                  <p class="title">Office Name:</p>
                  <p>
                    <span class="title list">{{ office.officeName }}</span>
                  </p>
                </div>
                <div
                  style="display: flex; flex-direction: row; flex-basis: 50%"
                >
                  <p class="title">Office Type:</p>
                  <p>
                    <span class="title list">{{ office.officeType }}</span>
                  </p>
                </div>
              </div>
              <div class="additional-details-list">
                <p class="title">Office Address:</p>
                <p>
                  <span class="title list">{{ office.officeAddress }}</span>
                </p>
              </div>
            </div>
            <a-divider class="section-divider" style="width: 100%" />
            <div class="form-sub-section">
              <div class="add-account-horizontal-elements">
                <a-form-item
                  style="
                    margin-bottom: 0.75rem;
                    flex-basis: 100%;
                    margin-right: 2rem;
                  "
                >
                  <span class="input-label">Sub Team</span>
                  <PlusOutlined
                    class="icon-style"
                    style="margin-left: 1rem"
                    @click="addSubTeamRGInfo"
                  />
                  <a-select
                    show-search
                    class="select-input"
                    placeholder="Sub Team"
                    :options="subTeamOptions"
                    label-in-value
                    v-model:value="subTeamRagObject.subTeamValue"
                    @search="fetchSubTeam"
                    :default-active-first-option="false"
                    :filter-option="false"
                  >
                  </a-select>
                </a-form-item>
                <a-form-item
                  style="
                    flex-basis: 100%;
                    margin-bottom: 0;
                    margin-left: 2rem;
                    padding-top: 1.35rem;
                  "
                >
                  <a-select
                    class="select-input"
                    placeholder="RAG Status"
                    label-in-value
                    v-model:value="subTeamRagObject.ragStatusValue"
                    :options="ragStatusOptions"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <MinusOutlined
                class="icon-style"
                style="margin-top: 3rem"
                @click="resetSubTeamRagFields"
              />
            </div>
            <div class="additional-details-list">
              <div style="display: flex; flex-direction: row; flex-basis: 58%">
                <p class="title">Sub Team Name:</p>
                <p
                  v-for="(subTeam, index) in subTeamRagObject.subTeamRagTable"
                  :key="index"
                >
                  <span class="title list">{{ subTeam.subTeam }}</span>
                </p>
              </div>
              <div style="display: flex; flex-direction: row; flex-basis: 50%">
                <p class="title">RAG Status:</p>
                <p
                  v-for="(subTeam, index) in subTeamRagObject.subTeamRagTable"
                  :key="index"
                >
                  <span class="title list">{{ subTeam.ragStatus }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="create-account-client-person"
          style="padding-left: 1rem; padding-right: 1rem"
        >
          <div class="create-account-client-person-form">
            <div class="title" style="padding-left: 1rem">
              Client Person <span class="optional-text">(Optional)</span>
            </div>
            <div
              class="form-sub-section"
              style="margin-left: 2rem; margin-right: 2rem"
            >
              <div class="add-account-form-elements left-form-elements">
                <a-form-item>
                  <span class="input-label">Unit</span
                  ><span class="required-input">*</span>
                  <a-select
                    class="select-input"
                    :options="unitTypeOptions"
                    placeholder="select"
                    v-model:value="clientPersonObject.unit"
                  ></a-select>
                  <a-checkbox v-model:checked="clientPersonStatus"
                    >Set as active</a-checkbox
                  >
                </a-form-item>
              </div>
              <div class="add-account-form-elements">
                <a-form-item>
                  <span class="input-label">Title</span>
                  <a-select
                    class="select-input"
                    :options="titleOptions"
                    placeholder="select"
                    v-model:value="clientPersonObject.title"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div
              class="form-sub-section"
              style="margin-left: 2rem; margin-right: 2rem"
            >
              <div class="add-account-form-elements left-form-elements">
                <a-form-item>
                  <span class="input-label">First Name</span>
                  <span class="required-input">*</span>
                  <a-input
                    class="form-input"
                    v-model:value="clientPersonObject.firstName"
                    @change="updateFullName"
                  ></a-input>
                </a-form-item>
              </div>
              <div class="add-account-form-elements">
                <a-form-item>
                  <span class="input-label">Last Name</span
                  ><span class="required-input">*</span>
                  <a-input
                    class="form-input"
                    v-model:value="clientPersonObject.lastName"
                    @change="updateFullName"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
            <div
              class="form-sub-section"
              style="margin-left: 2rem; margin-right: 2rem"
            >
              <div class="add-account-form-elements left-form-elements">
                <a-form-item>
                  <span class="input-label">Full Name</span>
                  <a-input
                    disabled
                    class="form-input"
                    placeholder="Full Name"
                    v-model:value="fullNameValue"
                  ></a-input>
                </a-form-item>
              </div>
              <div class="add-account-form-elements">
                <a-form-item>
                  <span class="input-label">DOB</span>
                  <a-date-picker
                    class="date-input"
                    v-model:value="clientPersonObject.dob"
                    style="border-radius: 15px !important; margin-top: 0.75rem"
                  ></a-date-picker>
                </a-form-item>
              </div>
            </div>
            <div
              class="form-sub-section"
              style="margin-left: 2rem; margin-right: 2rem"
            >
              <div class="add-account-form-elements left-form-elements">
                <a-form-item>
                  <span class="input-label">Position</span>
                  <span class="required-input">*</span>
                  <a-input
                    class="form-input"
                    v-model:value="clientPersonObject.position"
                  ></a-input>
                </a-form-item>
              </div>
              <div class="add-account-form-elements">
                <a-form-item>
                  <span class="input-label">Department</span>
                  <span class="required-input">*</span>
                  <a-select
                    show-search
                    class="select-input"
                    :options="departmentOptions"
                    v-model:value="clientPersonObject.departmentId"
                    @search="fetchDepartmentList"
                    label-in-value
                    :default-active-first-option="false"
                    :filter-option="false"
                  >
                  </a-select>
                </a-form-item>
              </div>
            </div>
            <div
              class="form-sub-section"
              style="margin-left: 2rem; margin-right: 2rem"
            >
              <div
                class="
                  add-account-form-elements
                  left-form-elements
                  multi-elements
                "
              >
                <a-form-item>
                  <span class="input-label">Mobile</span>
                  <span class="required-input">*</span>
                  <a-input
                    class="form-input"
                    v-model:value="clientPersonObject.mobile"
                  ></a-input>
                </a-form-item>
                <a-form-item>
                  <span class="input-label">Alternate Phone</span>
                  <a-input
                    class="form-input"
                    v-model:value="clientPersonObject.alternatePhone"
                  ></a-input>
                </a-form-item>
              </div>
              <div class="add-account-form-elements">
                <a-form-item>
                  <span class="input-label">Location(City)</span>
                  <span class="required-input">*</span>
                  <a-input
                    class="form-input"
                    v-model:value="clientPersonObject.address.city"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
            <div
              class="form-sub-section"
              style="margin-left: 2rem; margin-right: 2rem"
            >
              <div
                class="
                  add-account-form-elements
                  left-form-elements
                  multi-elements
                "
              >
                <a-form-item>
                  <span class="input-label">Email</span>
                  <a-input
                    class="form-input"
                    v-model:value="clientPersonObject.email"
                  ></a-input>
                </a-form-item>
                <a-form-item>
                  <span class="input-label">Alternate Email</span>
                  <a-input
                    class="form-input"
                    v-model:value="clientPersonObject.alternateEmail"
                  ></a-input>
                </a-form-item>
              </div>
              <div class="add-account-form-elements multi-elements">
                <a-form-item>
                  <span class="input-label">Birthday Bouquet</span>
                  <a-select
                    class="select-input"
                    :options="binaryOptions"
                    v-model:value="clientPersonObject.birthBouquet"
                  ></a-select>
                </a-form-item>
                <a-form-item>
                  <span class="input-label">Newsletter</span>
                  <a-select
                    class="select-input"
                    :options="binaryOptions"
                    v-model:value="clientPersonObject.newsLetter"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div
              class="form-sub-section"
              style="margin-left: 2rem; margin-right: 2rem"
            >
              <div
                class="
                  add-account-form-elements
                  left-form-elements
                  multi-elements
                "
                style="margin-right: 0"
              >
                <a-form-item style="flex-basis: 21%">
                  <span class="input-label">Address Type</span>
                  <a-select
                    class="select-input"
                    :options="addressTypeOptions"
                    v-model:value="clientPersonObject.address.type"
                  ></a-select>
                </a-form-item>
                <a-form-item style="flex-basis: 77%">
                  <span class="input-label">Address</span>
                  <a-input
                    class="form-input"
                    v-model:value="clientPersonObject.address.street"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
            <div class="add-comments-section">
              <div class="icon-block">
                <a-avatar
                  src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                />
              </div>
              <a-textarea
                class="form-text-area"
                v-model:value="clientPersonTextArea"
                :autoSize="{ minRows: 4, maxRows: 6 }"
              />
            </div>
            <div class="form-sub-section">
              <div class="add-account-horizontal-elements">
                <MinusOutlined
                  class="icon-style"
                  style="margin-right: 0.5rem; margin-top: 3rem"
                />
                <a-form-item style="flex-basis: 32%; padding-right: 1rem">
                  <span class="input-label">RM Status</span>
                  <a-select
                    class="select-input"
                    show-search
                    showArrow
                    placeholder="CIC"
                    :options="cicOptions"
                    label-in-value
                    v-model:value="rmStatusRef.cicValue"
                    @search="getCicData"
                    :default-active-first-option="false"
                    :filter-option="false"
                  ></a-select>
                </a-form-item>
                <a-form-item
                  style="
                    margin-top: 1.3rem;
                    flex-basis: 32%;
                    padding-right: 1rem;
                  "
                >
                  <a-select
                    class="select-input"
                    placeholder="RM Status"
                    label-in-value
                    :options="rmStatusOptions"
                    v-model:value="rmStatusRef.rmStatusValue"
                  ></a-select>
                </a-form-item>
                <a-form-item style="margin-top: 1.3rem; flex-basis: 32%">
                  <a-select
                    class="select-input"
                    placeholder="Priority"
                    label-in-value
                    :options="priorityOptions"
                    v-model:value="rmStatusRef.priorityValue"
                  ></a-select>
                </a-form-item>
                <PlusOutlined
                  class="icon-style"
                  style="margin-top: 3rem"
                  @click="addRmStatus"
                />
              </div>
            </div>
            <div class="added-details-list">
              <a-table
                class="clientPersonRMStatusTable"
                :columns="clientPersonRMStatusColumns"
                :data-source="rmStatusRef.rmStatusTable"
                :pagination="false"
                :row-selection="clientPersonRMStatusRowSelection"
              >
              <template #rmDelete="{ record }">
                <a-popconfirm
                  title="Are you sure delete you want to delete?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="confirmRmDelete(record.key)"
                  @cancel="cancel"
                >
                  <DeleteOutlined style="cursor: pointer" />
                </a-popconfirm>
              </template>
              </a-table>
            </div>
            <div class="client-person-action-buttons">
              <a-button type="link" @click="resetClientPerson">Clear</a-button>
              <a-button
                :disabled="officeRef.officeTableList.length === 0"
                class="save-button"
                @click="saveCientPerson"
                >Save</a-button
              >
            </div>
            <a-divider />
            <div class="added-details-list">
              <a-table
                class="clientPersonTable"
                :columns="clientPersonColumns"
                :data-source="clientPersonObject.clientPersonTable"
                :pagination="false"
                :row-selection="clientPersonRowSelection"
              >
              <template #clientDelete="{ record }">
                <a-popconfirm
                  title="Are you sure delete you want to delete?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="confirmClientDelete(record.key)"
                  @cancel="cancel"
                >
                  <DeleteOutlined style="cursor: pointer" />
                </a-popconfirm>
              </template>
              </a-table>
            </div>
          </div>
        </div>
        <div class="create-account-meeting" v-if="false">
          <div class="create-account-meeting-form">
            <div class="title" style="padding-left: 1rem">
              Meeting <span class="optional-text">(Optional)</span>
            </div>
            <div class="form-sub-section">
              <div
                class="
                  add-account-form-elements
                  left-form-elements
                  multi-elements
                "
                style="margin-right: 0"
              >
                <a-form-item style="flex-basis: 70%">
                  <span class="input-label">Client Person</span>
                  <span class="required-input">*</span>
                  <a-select
                    class="select-input"
                    mode="tags"
                    showArrow
                    style="margin-top: 0.75rem"
                  ></a-select>
                </a-form-item>
                <a-form-item style="flex-basis: 25%">
                  <span class="input-label">Title</span>
                  <a-select class="select-input"></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-account-form-elements left-form-elements">
                <a-form-item>
                  <span class="input-label">Department</span>
                  <a-select class="select-input"></a-select>
                </a-form-item>
              </div>
              <div class="add-account-form-elements">
                <a-form-item>
                  <span class="input-label">Team</span>
                  <span class="required-input">*</span>
                  <a-select class="select-input"></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-account-form-elements" style="margin-right: 0">
                <a-form-item>
                  <span class="input-label">Sub Team</span>
                  <span class="required-input">*</span>
                  <a-select
                    class="select-input"
                    mode="tags"
                    showArrow
                    style="margin-top: 0.75rem"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-account-form-elements left-form-elements">
                <a-form-item>
                  <span class="input-label">Meeting Type</span>
                  <a-select class="select-input"></a-select>
                </a-form-item>
              </div>
              <div class="add-account-form-elements">
                <a-form-item>
                  <span class="input-label">Lead CIC</span>
                  <span class="required-input">*</span>
                  <a-select class="select-input"></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-account-form-elements" style="margin-right: 0">
                <a-form-item>
                  <span class="input-label">Attended CIC</span>
                  <a-select
                    class="select-input"
                    mode="tags"
                    showArrow
                    style="margin-top: 0.75rem"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="add-comments-section" style="margin: 0 0 1.5rem">
              <div class="icon-block">
                <a-avatar
                  src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                />
              </div>
              <a-textarea
                class="form-text-area"
                v-model:value="meetingTextArea"
                :autoSize="{ minRows: 4, maxRows: 6 }"
              />
            </div>
            <div class="meeting-action-buttons">
              <a-button type="link" class="clear-button">Clear</a-button>
              <a-button class="save-button">Save</a-button>
            </div>
            <a-divider />
            <div class="added-details-list">
              <a-table
                class="meetingTable"
                :columns="meetingColumns"
                :pagination="false"
                :row-selection="clientPersonRowSelection"
              >
              </a-table>
            </div>
          </div>
        </div>
        <div
          class="create-acoount-add-documents"
          style="min-height: 25rem !important"
        >
          <div class="title" style="padding-left: 1rem">
            KYC <span class="optional-text">(Optional)</span>
          </div>

          <div class="upload-document-radio-btns">
            <p class="text">Attach Files</p>

            <a-radio-group
              v-model:value="documentUploadRadiobtns"
              class="radio-btns"
            >
              <a-radio :value="1">Static</a-radio>
              <a-radio :value="2">Dynamic</a-radio>
            </a-radio-group>
          </div>

          <div class="upload-component">
            <a-upload-dragger
              :multiple="true"
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              name="file"
              @change="handleChange"
              style="border-radius: 15px; border-color: rgba(229, 129, 106, 1)"
            >
              <p class="ant-upload-drag-icon">
                <CloudUploadOutlined style="color: rgba(229, 129, 106, 1)" />
              </p>
              <p class="ant-upload-text">
                Drag & drop or Click on this area to upload file
              </p>
            </a-upload-dragger>
          </div>
        </div>
        <div class="create-acoount-terms" style="padding: 1.75rem 1rem">
          <div class="create-acoount-terms-form">
            <div class="title" style="padding-left: 1rem">
              Terms <span class="optional-text">(Optional)</span>
            </div>
            <div class="form-sub-section" style="display: unset">
              <div class="add-account-horizontal-elements">
                <MinusOutlined
                  class="icon-style"
                  style="margin-right: 0.5rem; margin-top: 1.5rem"
                  @click="resetTerms"
                />
                <a-form-item style="flex-basis: 25%">
                  <a-input
                    class="form-input"
                    placeholder="Level"
                    v-model:value="termsRefObject.name"
                  ></a-input>
                  <a-checkbox
                    v-model:checked="termsStatus"
                    class="checkbox-input"
                  >
                    Set as active
                  </a-checkbox>
                </a-form-item>
                <a-form-item style="flex-basis: 18%">
                  <a-select
                    class="select-input"
                    placeholder="is Fixed"
                    :options="binaryOptions"
                    v-model:value="termsRefObject.isFixed"
                  ></a-select>
                </a-form-item>
                <a-form-item style="flex-basis: 18%">
                  <a-input-number
                    class="form-input"
                    placeholder="Fixed Fee"
                    :disabled="termsRefObject.isFixed === 'NO'"
                    v-model:value="termsRefObject.fixedFee"
                  ></a-input-number>
                </a-form-item>
                <a-form-item style="flex-basis: 18%">
                  <a-input-number
                    class="form-input"
                    placeholder="Percentage%"
                    :disabled="termsRefObject.isFixed === 'YES'"
                    v-model:value="termsRefObject.percentage"
                  ></a-input-number>
                </a-form-item>
                <a-form-item style="flex-basis: 18%">
                  <a-input-number
                    class="form-input"
                    placeholder="Fee Limit"
                    :disabled="termsRefObject.isFixed === 'YES'"
                    v-model:value="termsRefObject.fee"
                  ></a-input-number>
                </a-form-item>
                <PlusOutlined
                  class="icon-style"
                  style="margin-top: 1.5rem"
                  @click="addTerms"
                />
              </div>
            </div>
            <div class="added-details-list">
              <a-table
                class="termsTable"
                :data-source="termsRefObject.termsTable"
                :columns="termsColumns"
                :pagination="false"
                :showHeader="false"
              >
              </a-table>
            </div>
          </div>
        </div>
        <div class="form-action-btns">
          <a-button type="link" class="clear-button" href="/list-companies"
            >Exit</a-button
          >
          <a-button class="create-account-btn" @click="onSubmit">
            Create Account
          </a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import { Form, message } from "ant-design-vue";
import {
  PlusOutlined,
  MinusOutlined,
  CloudUploadOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons-vue";
import Services from "../../../services/apis";
import Utils from "../../../utils";
import { useRouter } from "vue-router";

const useForm = Form.useForm;
let fullNameValue = ref("");

let entityRef = reactive({
  entityValue: undefined,
  entityList: [],
});

let officeRef = reactive({
  officeName: "",
  officeTypeValue: undefined,
  officeAddress: {
    type: "PERMANENT",
    street: "",
    city: "Mumbai",
    country: "",
  },
  officeTableList: [],
  officeList: [],
});

let rmStatusRef = reactive({
  cicValue: undefined,
  rmStatusValue: undefined,
  priorityValue: undefined,
  rmStatusTable: [],
  rmStatusList: [],
});

let clientPersonObject = reactive({
  unit: "",
  title: "",
  status: "",
  firstName: "",
  lastName: "",
  dob: "",
  position: "",
  departmentId: undefined,
  mobile: "",
  alternatePhone: "",
  email: "",
  alternateEmail: "",
  birthBouquet: "",
  newsLetter: "",
  address: {
    type: "",
    street: "",
    city: "",
  },
  rm: [
    {
      cic: undefined,
      status: undefined,
      priority: 0,
    },
  ],
  clientPersonTable: [],
});

let subTeamRagObject = reactive({
  subTeamValue: undefined,
  ragStatusValue: undefined,
  subTeamRagTable: [],
  subTeamRagList: [],
});

let termsRefObject = reactive({
  name: "",
  isFixed: undefined,
  fixedFee: undefined,
  status: "",
  percentage: undefined,
  fee: undefined,
  termsList: [],
  termsTable: [],
});

const addEntities = () => {
  if (entityRef.entityValue) {
    let entityValue = entityRef.entityValue;
    let entityList = entityRef.entityList;
    if (entityValue && entityList.indexOf(entityValue) === -1) {
      entityList = [...entityList, entityValue];
    } else {
      message.error("Entity " + entityValue + " already added");
    }

    Object.assign(entityRef, {
      entityValue: undefined,
      entityList,
    });
  } else {
    message.error("Entity Name is required fields");
  }
};

const resetEntities = () => {
  entityRef.entityValue = undefined;
};
const resetOfficeFields = () => {
  officeRef.officeName = "";
  officeRef.officeTypeValue = undefined;
  officeRef.officeAddress = {
    type: "PERMANENT",
    street: "",
    city: "Mumbai",
    country: "",
  };
};

const resetClientPerson = () => {
  clientPersonObject.unit = "";
  clientPersonObject.title = "";
  clientPersonObject.status = "";
  clientPersonObject.firstName = "";
  clientPersonObject.lastName = "";
  clientPersonObject.dob = "";
  clientPersonObject.position = "";
  clientPersonObject.departmentId = undefined;
  clientPersonObject.mobile = "";
  clientPersonObject.alternatePhone = "";
  clientPersonObject.email = "";
  clientPersonObject.alternateEmail = "";
  clientPersonObject.birthBouquet = "";
  clientPersonObject.newsLetter = "";
  clientPersonObject.address.type = "";
  clientPersonObject.address.street = "";
  clientPersonObject.address.city = "";
  clientPersonObject.rm.cic = "";
  clientPersonObject.rm.status = "";
  clientPersonObject.rm.priority = "";
};

const resetSubTeamRagFields = () => {
  subTeamRagObject.subteamValue = undefined;
  subTeamRagObject.ragStatusValue = undefined;
};
const resetTerms = () => {
  (termsRefObject.name = ""), (termsRefObject.isFixed = undefined);
  termsRefObject.fixedFee = undefined;
  termsRefObject.status = "";
  termsRefObject.percentage = undefined;
  termsRefObject.fee = undefined;
};

const clientPersonRMStatusColumns = [
  {
    key: "cic",
    dataIndex: "cic",
    title: "CIC",
    colSpan: 1,
    textalign: "left",
  },
  {
    key: "rmstatus",
    dataIndex: "rmstatus",
    title: "RM Status",
    colSpan: 1,
    textalign: "center",
  },
  {
    key: "priority",
    dataIndex: "priority",
    title: "Priority",
  },
  {
  title: "",
  dataIndex: "rmDelete",
  key: "rmDelete",
  align: "right",
  slots: { customRender: "rmDelete" },
  width: 150,
},
];

const termsColumns = [
  {
    key: "name",
    dataIndex: "name",
    title: "Level",
    align: "center",
  },
  {
    key: "isFixed",
    dataIndex: "isFixed",
    title: "Is Fixed",
    textalign: "center",
  },
  {
    key: "fixedFee",
    dataIndex: "fixedFee",
    title: "Fixed Fee",
    align: "center",
  },
  {
    key: "percentage",
    dataIndex: "percentage",
    title: "Percentage%",
    align: "center",
  },
  {
    key: "fee",
    dataIndex: "fee",
    title: "Fee Limit",
    align: "center",
  },
];

const clientPersonRMStatusRowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
};

const clientPersonColumns = [
  {
    key: "unit",
    dataIndex: "unit",
    title: "Unit",
    textalign: "left",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Name",
    colSpan: 1,
    textalign: "center",
  },
  {
    key: "designation",
    dataIndex: "designation",
    title: "Designation",
  },
  {
    key: "mobile",
    dataIndex: "mobile",
    title: "Mobile No.",
  },
  {
    key: "phone",
    dataIndex: "phone",
    title: "Phone Office",
  },
  {
    key: "email",
    dataIndex: "email",
    title: "Email ID",
  },
  {
    key: "dob",
    dataIndex: "dob",
    title: "DOB",
  },
  {
    title: "",
    dataIndex: "clientDelete",
    key: "clientDelete",
    align: "right",
    slots: { customRender: "clientDelete" },
    width: 150,
  },
];

const meetingColumns = [
  {
    key: "clientPerson",
    dataIndex: "clientPerson",
    title: "Client Person",
    textalign: "left",
  },
  {
    key: "date",
    dataIndex: "date",
    title: "Date",
    textalign: "center",
  },
  {
    key: "department",
    dataIndex: "department",
    title: "Department",
  },
  {
    key: "lead_cic",
    dataIndex: "lead_cic",
    title: "Lead CIC",
  },
  {
    key: "attended_cic",
    dataIndex: "attended_cic",
    title: "Attended CIC",
  },
  {
    key: "tracker_type",
    dataIndex: "tracker_type",
    title: "Tracker Type",
  },
  {
    key: "team",
    dataIndex: "team",
    title: "Team",
  },
  {
    key: "sub_team",
    dataIndex: "sub_team",
    title: "Sub Team",
  },
];

const clientPersonRowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
};

export default defineComponent({
  setup() {
    const dateFormat = "YYYY/MM/DD";
    const router = useRouter();
    const formRef = ref();
    const confirmRmDelete = (key) => {
      rmStatusRef.rmStatusTable =
        rmStatusRef.rmStatusTable.filter(
          (item) => item.key !== key
        );
    };
    const confirmClientDelete = (key) => {
      clientPersonObject.clientPersonTable =
        clientPersonObject.clientPersonTable.filter(
          (item) => item.key !== key
        );
    };
    

    let binaryOptions = ref([
      { value: "YES", label: "Yes" },
      { value: "NO", label: "No" },
    ]);
    let priorityOptions = ref([
      { value: "LOW", label: "Low" },
      { value: "MEDIUM", label: "Medium" },
      { value: "HIGH", label: "High" },
    ]);
    let addressTypeOptions = ref([
      { value: "PERMANENT", label: "Permanent" },
      { value: "COMMUNICATION", label: "Communication" },
    ]);
    let profileTextArea = ref("");
    let clientPersonTextArea = ref("");
    let meetingTextArea = ref("");
    let cicOptions = ref([]);
    let rmStatusOptions = ref([]);
    let companyTypeOptions = ref([]);
    let titleOptions = ref([]);
    let officeTypeOptions = ref([]);
    let unitTypeOptions = ref([]);
    let accountManagerOptions = ref([]);
    let sectorOptions = ref([]);
    let industryOptions = ref([]);
    let subTeamOptions = ref([]);
    let ragStatusOptions = ref([]);
    let departmentOptions = ref([]);
    let documentUploadRadiobtns = ref(1);

    let companyStatus = ref(true);
    let clientPersonStatus = ref(true);
    let termsStatus = ref(true);

    let modelRef = reactive({
      name: "",
      companyId: "",
      type: "",
      website: "",
      date: "",
      status: "",
      accountManagers: [],
      sectors: [],
      industries: [],
      profile: "",
      entities: [],
      offices: [
        {
          name: "",
          type: "",
          address: {
            type: "PERMANENT",
            street: "",
            city: "Mumbai",
            country: "",
          },
          clientPersons: [
            {
              title: "",
              status: "",
              firstName: "",
              lastName: "",
              dob: "",
              position: "",
              departmentId: "",
              mobile: "",
              alternatePhone: "",
              email: "",
              alternateEmail: "",
              birthBouquet: "",
              newsLetter: "",
              address: {
                type: "",
                street: "",
                city: "",
              },
              rm: [
                {
                  cic: "",
                  status: "",
                  priority: "",
                },
              ],
            },
          ],
        },
      ],
      documents: [],
      subTeams: [
        {
          id: "",
          ragStatusId: "",
        },
      ],
      terms: [
        {
          name: "",
          isFixed: true,
          status: "",
          percentage: 0,
          fee: 0,
        },
      ],
    });

    const rulesRef = reactive({
      name: [
        {
          required: true,
          message: "Please enter Company Name",
        },
      ],
      companyId: [
        {
          required: true,
          message: "Please enter Company Id",
        },
      ],
      type: [
        {
          required: true,
          message: "Please select Company Type",
        },
      ],
      accountManagers: [
        {
          required: true,
          message: "Please select atleast one Account Manager",
        },
      ],
      sectors: [
        {
          required: true,
          message: "Please select atleast one Sector type",
        },
      ],
      industries: [
        {
          required: true,
          message: "Please select atleast one Industry type",
        },
      ],
      profile: [
        {
          required: true,
          message: "Please enter profile information",
        },
      ],
    });

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    );

    const fetchSectorData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getSectorDetail(payload)
        .then((response) => {
          const { data } = response;
          sectorOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchIndustryData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getIndustryDetail(payload)
        .then((response) => {
          const { data } = response;
          industryOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    const updateFullName = () => {
      fullNameValue.value =
        clientPersonObject.firstName + " " + clientPersonObject.lastName;
    };
    const fetchUserData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", type: "MEMBER", pageNo: 0 };
      Services.userListing(payload)
        .then((response) => {
          const { data } = response;
          accountManagerOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    const getCicData = (value) => {
      let payload = {
        q: value,
        pageNo: 0,
        mode: "AUTOCOMPLETE",
      };

      Services.userListing(payload)
        .then((response) => {
          const { data } = response;
          cicOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    // Sub Team Listing
    const fetchSubTeam = (value) => {
      Services.teamListing({ types: ["SUB_TEAM"], q: value })
        .then((response) => {
          const { data } = response;
          subTeamOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    //Fetch Client Persons Unit Types
    const fetchUnitTypes = () => {
      if (officeRef.officeTableList.length) {
        unitTypeOptions.value = officeRef.officeTableList.map(
          ({ officeName: value, officeName: label, ...rest }) => ({
            value,
            label,
            ...rest,
          })
        );
      }
    };

    const fetchDepartmentList = (value) => {
      Services.getDepartmentsDetail({ q: value })
        .then((response) => {
          const { data } = response;
          departmentOptions.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    // OnMounted

    onMounted(() => {
      fetchSectorData();
      fetchIndustryData();
      fetchUserData();
      getCicData();
      fetchSubTeam();
      fetchDepartmentList();
      // RM Status Listing
      Services.getRmStatusDetail({ types: ["RM"] })
        .then((response) => {
          const { data } = response;
          rmStatusOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      // RAG STATUS Listing
      Services.getRmStatusDetail({ types: ["RM"] })
        .then((response) => {
          const { data } = response;
          ragStatusOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      // Company Type Listing
      Services.genderListing({ types: ["COMPANY_TYPES"] })
        .then((response) => {
          const { data } = response;
          companyTypeOptions.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      // title Listing
      Services.genderListing({ types: ["TITLE"] })
        .then((response) => {
          const { data } = response;
          titleOptions.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      Services.resumeListing({ types: ["UNIT"] })
        .then((response) => {
          const { data } = response;
          officeTypeOptions.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    });

    const addOfficeInfo = () => {
      if (
        officeRef.officeName &&
        officeRef.officeTypeValue &&
        officeRef.officeAddress.street
      ) {
        let officeName = officeRef.officeName;
        let country = officeRef.officeAddress.country;
        let officeTypeListValue = officeRef.officeTypeValue.label;
        let officeTypeValue = officeRef.officeTypeValue.value;
        let street = officeRef.officeAddress.street;
        let officeTableList = officeRef.officeTableList;
        let officeList = officeRef.officeList;

        let id = officeTableList.length + 1;
        let officeObject = {
          key: id,
          officeName: officeName ? officeName : "-",
          officeType: officeTypeListValue ? officeTypeListValue : "",
          officeAddress: street || country ? street + ", " + country : "-",
        };
        let office = {
          name: officeName ? officeName : "",
          type: officeTypeValue ? officeTypeValue : "",
          address: {
            type: "PERMANENT",
            street: street,
            city: "Mumbai",
            country: country,
          },
          clientPersons: [],
          isDefault: true,
        };
        officeTableList.push(officeObject);
        officeList.push(office);
        Object.assign(officeRef, {
          officeName: "",
          officeTypeValue: undefined,
          officeAddress: {
            type: "PERMANENT",
            street: "",
            city: "Mumbai",
            country: "",
          },
          officeTableList,
          officeList,
        });
        fetchUnitTypes();
      } else {
        message.error(
          "Office Name, Office Type, Office Address are required fields"
        );
      }
    };

    const addSubTeamRGInfo = () => {
      if (subTeamRagObject.subTeamValue && subTeamRagObject.ragStatusValue) {
        let subTeamValue = subTeamRagObject.subTeamValue;
        let ragStatusValue = subTeamRagObject.ragStatusValue;
        let subTeamRagTable = subTeamRagObject.subTeamRagTable;
        let subTeamRagList = subTeamRagObject.subTeamRagList;
        let id = subTeamRagTable.length + 1;
        let ragObject = {
          key: id,
          subTeam: subTeamValue ? subTeamValue.label : "-",
          ragStatus: ragStatusValue ? ragStatusValue.label : "-",
        };
        let subTeamRag = {
          id: subTeamValue ? subTeamValue.value : "-",
          ragStatusId: ragStatusValue ? ragStatusValue.value : "-",
        };
        subTeamRagTable.push(ragObject);
        subTeamRagList.push(subTeamRag);
        Object.assign(subTeamRagObject, {
          subTeamValue: undefined,
          ragStatusValue: undefined,
          subTeamRagTable,
          subTeamRagList,
        });
      } else {
        message.error("SubTeam and Rag Status are required fields");
      }
    };

    const addRmStatus = () => {
      if (
        rmStatusRef.cicValue &&
        rmStatusRef.rmStatusValue &&
        rmStatusRef.priorityValue
      ) {
        let cicValue = rmStatusRef.cicValue.label;
        let cic = rmStatusRef.cicValue.value;
        let rmStatusValue = rmStatusRef.rmStatusValue.label;
        let rmStatus = rmStatusRef.rmStatusValue.value;
        let priorityValue = rmStatusRef.priorityValue.label;
        let priority = rmStatusRef.priorityValue.value;
        let rmStatusList = rmStatusRef.rmStatusList;
        let rmStatusTable = rmStatusRef.rmStatusTable;

        let id = rmStatusTable.length + 1;
        let rmStatusIdObject = {
          key: id,
          cic: cicValue,
          rmstatus: rmStatusValue,
          priority: priorityValue,
        };
        let rmStatusObject = {
          cic: cic,
          status: rmStatus,
          priority: priority,
        };
        rmStatusList.push(rmStatusObject);
        rmStatusTable.push(rmStatusIdObject);

        Object.assign(rmStatusRef, {
          cicValue: undefined,
          rmStatusValue: undefined,
          priorityValue: undefined,
          rmStatusList,
          rmStatusTable,
        });
      } else {
        message.error("CIC, RM Status and Priority Fields are required fields");
      }
    };

    const saveCientPerson = () => {
      if (
        clientPersonObject.firstName &&
        clientPersonObject.lastName &&
        clientPersonObject.position &&
        clientPersonObject.departmentId &&
        clientPersonObject.mobile &&
        clientPersonObject.address.city
      ) {
        let unit = clientPersonObject.unit;
        let title = clientPersonObject.title;
        let firstName = clientPersonObject.firstName;
        let lastName = clientPersonObject.lastName;
        let dob = clientPersonObject.dob;
        let position = clientPersonObject.position;
        let departmentId = clientPersonObject.departmentId.value;
        let mobile = clientPersonObject.mobile;
        let alternatePhone = clientPersonObject.alternatePhone;
        let email = clientPersonObject.email;
        let alternateEmail = clientPersonObject.alternateEmail;
        let birthBouquet = clientPersonObject.birthBouquet;
        let newsLetter = clientPersonObject.newsLetter;
        let address_type = clientPersonObject.address.type;
        let address_street = clientPersonObject.address.street;
        let address_city = clientPersonObject.address.city;
        let rm = [];
        Object.assign(rm, rmStatusRef.rmStatusList);

        let officeObject = officeRef.officeList.filter(
          (office) => office.name === unit
        );
        // let clientPersonList = officeObject.clientPersons;
        let clientPersonTable = clientPersonObject.clientPersonTable;

        let id = clientPersonTable.length + 1;

        let clientPersonTableObject = {
          key: id,
          unit: unit ? unit : "-",
          status: clientPersonStatus.value ? "ACTIVE" : "INACTIVE",
          name: fullNameValue.value ? fullNameValue.value : "-",
          designation: position ? position : "-",
          mobile: mobile ? mobile : "-",
          phone: alternatePhone ? alternatePhone : "-",
          email: email ? email : "-",
          dob: dob ? Utils.displayLongDate(dob) : "-",
        };
        let clientPersonListObject = {
          title: title ? title : "-",
          firstName: firstName ? firstName : "",
          lastName: lastName ? lastName : "",
          dob: dob ? Utils.displayLongDate(dob) : "",
          position: position ? position : "",
          departmentId: departmentId ? departmentId : "",
          mobile: mobile ? mobile : "",
          alternatePhone: alternatePhone ? alternatePhone : "",
          email: email ? email : "",
          alternateEmail: alternateEmail ? alternateEmail : "",
          birthBouquet: birthBouquet ? birthBouquet : "",
          newsLetter: newsLetter ? newsLetter : "",
          address: {
            type: address_type ? address_type : "",
            street: address_street ? address_street : "",
            city: address_city ? address_city : "",
          },
          rm: rm,
        };
        clientPersonTable.push(clientPersonTableObject);
        let clientPersonList = [];
        clientPersonList = officeObject[0].clientPersons;
        clientPersonList.push(clientPersonListObject);
        officeObject[0]["clientPersons"] = clientPersonList;
        Object.assign(clientPersonObject, {
          unit: "",
          title: "",
          status: "",
          firstName: "",
          lastName: "",
          dob: "",
          position: "",
          departmentId: undefined,
          mobile: "",
          alternatePhone: "",
          email: "",
          alternateEmail: "",
          birthBouquet: "",
          newsLetter: "",
          address: {
            type: "",
            street: "",
            city: "",
          },
          rm: [],
          clientPersonTable,
        });
        rmStatusRef.rmStatusTable.splice(0);
        rmStatusRef.rmStatusList.splice(0);
      } else {
        message.error(
          "Please provide values for all the required fields within Client Person Section"
        );
      }
    };

    const addTerms = () => {
      if (termsRefObject.name && termsRefObject.isFixed) {
        let termsList = termsRefObject.termsList;
        let termsTable = termsRefObject.termsTable;
        let termsListObject = {
          name: termsRefObject.name,
          isFixed: termsRefObject.isFixed === "YES" ? true : false,
          fixedFee: termsRefObject.fixedFee
            ? parseInt(termsRefObject.fixedFee)
            : 0,
          percentage: termsRefObject.percentage
            ? parseInt(termsRefObject.percentage)
            : 0,
          fee: termsRefObject.fee ? parseInt(termsRefObject.fee) : 0,
          status: termsStatus.value ? "ACTIVE" : "INACTIVE",
        };
        let termsTableObject = {
          name: termsRefObject.name,
          isFixed: termsRefObject.isFixed,
          fixedFee: termsRefObject.fixedFee
            ? parseInt(termsRefObject.fixedFee)
            : 0,
          percentage: termsRefObject.percentage
            ? parseInt(termsRefObject.percentage)
            : 0,
          fee: termsRefObject.fee ? parseInt(termsRefObject.fee) : 0,
          status: termsStatus.value ? "ACTIVE" : "INACTIVE",
        };
        let id = termsList.length + 1;
        termsList.push(termsListObject);
        termsTable.push(termsTableObject);
        console.log("termsList :>> ", termsTable);
        Object.assign(termsRefObject, {
          id: id,
          name: "",
          isFixed: undefined,
          fixedFee: undefined,
          status: "",
          percentage: undefined,
          fee: undefined,
          termsList,
          termsTable,
        });
      } else {
        message.error("Level and IsFixed are Required");
      }
    };

    const handleChange = (info) => {
      const status = info.file.status;

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }

      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        let filesLists = info.fileList;
        for (let i = 0; i < filesLists; i++) {
          modelRef.documents.push(filesLists[i].name);
        }
        console.log(info.fileList);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    };

    const onSubmit = () => {
      validate().then((result) => {
        modelRef.status = companyStatus.value ? "ACTIVE" : "INACTIVE";
        // modelRef.date = modelRef.date ? Utils.displayLongDate(modelRef.date) : "";
        modelRef.entities = entityRef.entityList;
        modelRef.offices = officeRef.officeList;
        modelRef.subTeams = subTeamRagObject.subTeamRagList;
        modelRef.terms = termsRefObject.termsList;
        //   .then((result) => {
        console.log("result :>> ", modelRef);
        Services.createAccount(modelRef).then((result) => {
          const { data } = result;
          message.success("Client Created successfully");
          router.push("/list-companies");
        });
      })
      .catch((err) => {});
      // .catch((err) => {
      //   message.error("Please provide values for all the required Fields");
      //   console.log("result :>> ", err);
      // });
    };

    return {
      dateFormat,
      confirmRmDelete,
      confirmClientDelete,
      formRef,
      modelRef,
      validate,
      validateInfos,
      resetFields,
      documentUploadRadiobtns,
      fullNameValue,
      // Table Row Selection Variables
      clientPersonRowSelection,
      clientPersonRMStatusRowSelection,
      // Table Column Definitions
      clientPersonColumns,
      updateFullName,
      meetingColumns,
      clientPersonRMStatusColumns,
      termsColumns,
      // Select Option Variables
      cicOptions,
      rmStatusOptions,
      ragStatusOptions,
      subTeamOptions,
      binaryOptions,
      addressTypeOptions,
      priorityOptions,
      titleOptions,
      officeTypeOptions,
      unitTypeOptions,
      companyTypeOptions,
      accountManagerOptions,
      sectorOptions,
      industryOptions,
      departmentOptions,

      // Select Input variables
      birthdayValue: ref(""),
      termsSelectValue: ref(""),
      newsletterValue: ref(""),
      companyType: ref(""),
      account_manager: ref([]),
      sector: ref([]),
      industry: ref([]),

      // Funtions
      fetchSectorData,
      fetchIndustryData,
      fetchUserData,
      getCicData,
      fetchSubTeam,
      fetchDepartmentList,
      addEntities,
      resetEntities,
      resetOfficeFields,
      resetSubTeamRagFields,
      resetClientPerson,
      resetTerms,
      addTerms,
      addRmStatus,
      addOfficeInfo,
      addSubTeamRGInfo,
      saveCientPerson,
      handleChange,
      onSubmit,
      // Table data objects
      entityRef,
      officeRef,
      rmStatusRef,
      subTeamRagObject,
      clientPersonObject,
      termsRefObject,
      // Text Area Variabbles
      profileTextArea,
      clientPersonTextArea,
      meetingTextArea,

      // Set as Active CheckBox value
      companyStatus,
      clientPersonStatus,
      termsStatus,
    };
  },
  components: {
    PlusOutlined,
    MinusOutlined,
    CloudUploadOutlined,
    // CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    // QuillEditor,
  },
});
</script>

<style lang="scss">
@import "../styles/CreateCompany.scss";
</style>
